import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { Link, useNavigate } from 'react-router-dom';
import fm from 'front-matter';
import { format } from 'date-fns';

const BlogPage = ({ user }) => {
  const [posts, setPosts] = useState([]);
  const [selectedPost, setSelectedPost] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const importAll = (r) => r.keys().map(r);
    const markdownFiles = importAll(require.context('../blog-posts', false, /\.md$/));
    Promise.all(markdownFiles.map(file => fetch(file).then(res => res.text())))
      .then(files => {
        const parsedPosts = files.map(file => {
          const { attributes, body } = fm(file);
          return { ...attributes, content: body };
        });
        parsedPosts.sort((a, b) => new Date(b.date) - new Date(a.date));
        setPosts(parsedPosts);
      })
      .catch(err => console.error(err));
  }, []);

  const handlePostClick = (post) => {
    setSelectedPost(post);
  };

  const handleLoginClick = () => {
    navigate('/account');
  };

  const components = {
    p: ({node, ...props}) => <p className="mb-6" {...props} />
  };

  const renderPosts = () => {
    const shouldBlur = !user && posts.length > 1;

    return (
      <div className="relative">
        <ul className="divide-y divide-gray-300">
          {posts.map((post, index) => (
            <li key={post.id || index} className="py-4">
              <Link to="#" className="block">
                <div className="bg-white rounded-lg shadow-md overflow-hidden">
                  <div className="p-4">
                    <h3 className="text-xl font-bold text-blue-600 mb-2">{post.title}</h3>
                    <p className="text-gray-600 mb-2">{format(new Date(post.date), 'PPP')}</p>
                    <p className="text-gray-700">{post.excerpt}</p>
                  </div>
                  <button
                    className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 block w-full text-center font-semibold"
                    onClick={() => handlePostClick(post)}
                  >
                    Read More
                  </button>
                </div>
              </Link>
            </li>
          ))}
        </ul>
        {shouldBlur && (
          <div className="absolute inset-0 top-[200px] bg-gray-200 bg-opacity-50 backdrop-blur-sm flex flex-col items-center justify-start pt-8">
            <p className="text-lg font-semibold mb-4">Login to view all articles</p>
            <button
              onClick={handleLoginClick}
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
            >
              Login
            </button>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="bg-gray-200 min-h-screen py-8">
      <div className="max-w-3xl mx-auto px-4">
        <h1 className="text-center text-3xl font-bold text-gray-900 mb-8">Latest Daily Lockz News</h1>
        {selectedPost ? (
          <div>
            <button 
              className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg mb-4" 
              onClick={() => setSelectedPost(null)}
            >
              Back to Posts
            </button>
            <h2 className="text-2xl font-bold text-blue-600 mb-4">{selectedPost.title}</h2>
            <p className="text-gray-600 mb-4">{format(new Date(selectedPost.date), 'PPP')}</p>
            <div className="prose max-w-none">
              <ReactMarkdown components={components}>{selectedPost.content}</ReactMarkdown>
            </div>
          </div>
        ) : (
          renderPosts()
        )}
      </div>
    </div>
  );
};

export default BlogPage;