import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="max-w-2xl mx-auto p-6 bg-white shadow-md rounded-lg">
      <h1 className="text-3xl font-bold mb-4 text-gray-800">Privacy Policy</h1>
      <p className="mb-4 text-gray-600">
        Our Privacy Policy is hosted externally. You can view it by clicking the link below:
      </p>
      <a 
        href="https://www.freeprivacypolicy.com/live/e6d351cc-a63c-4bcc-8636-34d97a131166" 
        target="_blank" 
        rel="noopener noreferrer"
        className="inline-block bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-300"
      >
        View our Privacy Policy
      </a>
    </div>
  );
};

export default PrivacyPolicy;