import React from 'react';

const OurData = () => {
  return (
    <div className="bg-gray-100 p-6 rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-4 text-blue-700">Unleashing the Power of Data</h2>
      <p className="mb-4 text-gray-700">
        At your fingertips lies the power of cutting-edge data analysis and machine learning. We leverage the most advanced sports statistics to provide you with the most accurate predictions possible.
      </p>
      <h3 className="text-xl font-semibold mb-2 text-blue-600">Building a Statistical Fortress</h3>
      <p className="mb-4 text-gray-700">
        Our data acquisition process is multifaceted. We source information from a variety of reliable sources, including:
      </p>
      <ul className="list-disc list-inside mb-4 text-gray-700">
        <li>Official league statistics providers</li>
        <li>Advanced sports data platforms</li>
        <li>Expert scouting reports and player performance metrics</li>
      </ul>
      <p className="mb-4 text-gray-700">
        This comprehensive approach ensures we capture a wide range of factors that can influence game outcomes.
      </p>
      <h3 className="text-xl font-semibold mb-2 text-blue-600">Machine Learning: The Engine of Prediction</h3>
      <p className="mb-4 text-gray-700">
        We don't just throw data at a wall and hope it sticks. Our team of data scientists trains sophisticated machine learning models on thousands of data columns. These models, powered by deep neural networks, can identify complex patterns and relationships within the data.
      </p>
      <p className="mb-4 text-gray-700">
        By analyzing historical data, game trends, and player performance, our models learn to predict future outcomes with exceptional accuracy.
      </p>
      <h3 className="text-xl font-semibold mb-2 text-blue-600">Beyond the Numbers: The Human Touch</h3>
      <p className="mb-4 text-gray-700">
        While our models are powerful, we understand the importance of human expertise. Our data scientists and sports analysts work hand-in-hand to refine the models and ensure they account for factors beyond pure statistics, such as injuries, team chemistry, and motivational elements.
      </p>
      <p className="mb-4 text-gray-700">
        This combined approach of advanced data and human knowledge delivers the most insightful and accurate predictions for you.
      </p>
      <h3 className="text-xl font-semibold mb-2 text-blue-600">Empowering Your Decisions</h3>
      <p className="text-gray-700">
        We don't tell you what to bet on; we empower you with the knowledge you need to make informed decisions. Our data-driven predictions provide a powerful edge, allowing you to approach sports betting with greater confidence.
      </p>
    </div>
  );
};

export default OurData;