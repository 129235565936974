import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <header className="bg-black text-white flex justify-between items-center p-4 shadow-md z-50 relative">
      <div className="flex items-center">
        <Link to="/">
          <img src="/dailylockzlogo.png" alt="Daily Lockz Logo" className="h-12 w-auto" />
        </Link>
      </div>
      <div className="block lg:hidden">
        <button
          className="hamburger-menu focus:outline-none"
          onClick={toggleMenu}
          aria-label="Toggle Menu"
        >
          <span className="block w-6 h-0.5 bg-white my-1 transition-all duration-300"></span>
          <span className="block w-6 h-0.5 bg-white my-1 transition-all duration-300"></span>
          <span className="block w-6 h-0.5 bg-white my-1 transition-all duration-300"></span>
        </button>
      </div>
      <nav className={`fixed top-0 right-0 h-full w-64 bg-black text-white transition-transform duration-300 ease-in-out transform ${isMenuOpen ? 'translate-x-0' : 'translate-x-full'} lg:static lg:transform-none lg:flex lg:flex-col lg:w-auto z-40`}>
        <ul className="flex flex-col items-center lg:flex-row lg:space-x-6 lg:space-y-0 mt-24 lg:mt-0">
          <li>
            <Link to="/" className="nav-link hover:bg-blue-500 py-2 px-4 rounded block" onClick={closeMenu}>
              Home
            </Link>
          </li>
          <li>
            <Link to="/games" className="nav-link hover:bg-blue-500 py-2 px-4 rounded block" onClick={closeMenu}>
              Games
            </Link>
          </li>
          <li>
            <Link to="/blog" className="nav-link hover:bg-blue-500 py-2 px-4 rounded block" onClick={closeMenu}>
              News
            </Link>
          </li>
          <li>
            <Link to="/account" className="nav-link hover:bg-blue-500 py-2 px-4 rounded block" onClick={closeMenu}>
              Account
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;