import React from 'react';

const AboutUs = () => {
  return (
    <div className="bg-gray-100 p-6">
      <h1 className="text-3xl font-bold mb-4">About Us</h1>
      <p className="mb-4">
        Welcome to Daily Lockz, we provide you with predictions for every single game, every single day. Daily Lockz also uses the most advanced stats and machine learning models for insanely accurate predictions.
      </p>
      <p className="mb-4">
        Our goal is to empower you to make informed decisions when placing your sports bets. We don't have any "Pro Sports Bettors" or "Sports Betting Analysts" (which do not exist by the way) telling you which dumb picks to make.
        Instead, we have advanced models trained with advanced stats to show you exactly what the score of a game should be. 
      </p>
      <p className="mb-4">
        To use our game predictions, understand that the score of the game will not be exactly what we predicted. Rather, the
        score you are seeing in our predictions is what the sportsbooks 'should' expect the score to be to make their odds. Therefore, you
        can compare our predictions to sportsbooks odds to find +EV bets.   
      </p>
      <a href="/evbetting" className="text-blue-500 underline mb-4 inline-block"> +EV Betting</a>
      <p className="mb-4">
        Whether you're a seasoned sports bettor or just starting out, we believe our website can be a valuable resource for you. We encourage you to explore our picks, do your own research, and ultimately make your own betting decisions.
      </p>
      <p>
        We're constantly working to improve our website and provide you with the best possible experience. If you have any feedback or suggestions, please don't hesitate to contact us.
      </p>
    </div>
  );
};

export default AboutUs;
