import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './components/HomePage';
import PicksPage from './components/PicksPage';
import GameDetailsPage from './components/GameDetailsPage';
import BlogPage from './components/BlogPage';
import AccountPage from './components/Account';
import RegisterPage from './components/RegisterPage';
import LogoutPage from './components/LogoutPage';
import ScrollToTop from './components/ScrollToTop';
import AboutUsPage from './components/footer/AboutUs';
import ContactUsPage from './components/footer/ContactUs';
import PrivacyPolicyPage from './components/footer/PrivacyPolicy';
import TOSPage from './components/footer/TermsOfService';
import GamblingResponsiblyPage from './components/footer/GamblingResponsibly';
import EVBettingPage from './components/footer/EVBetting';
import OurDataPage from './components/footer/OurData';
import Sitemap from './components/footer/Sitemap';
import Papa from 'papaparse';

function App() {
  const [picks, setPicks] = useState([]);
  const [curDate, setCurDate] = useState('');
  const [user, setUser] = useState(null);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });
  }, []);

  useEffect(() => {
    const fetchPicks = async () => {
      try {
        const response = await fetch('/all_sims.csv');
        const reader = response.body.getReader();
        const result = await reader.read();
        const decoder = new TextDecoder('utf-8');
        const csv = decoder.decode(result.value);
        const { data } = Papa.parse(csv, { header: true });
        const validData = data.filter(game =>
          game.sport && game.win_prob &&
          game.home_team && game.away_team &&
          game.h_score && game.a_score &&
          game.h_logo && game.a_logo && game.time && game.cur_date &&
          game.is_dl
        );
        console.log('Parsed CSV data:', validData);
        if (validData.length > 0) {
          setCurDate(validData[0].cur_date);
        }
        setPicks(validData);
      } catch (error) {
        console.error('Error fetching or parsing CSV:', error);
      }
    };
    fetchPicks();
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <Header />
        <Routes>
          {/* Public routes */}
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutUsPage />} />
          <Route path="/contact" element={<ContactUsPage />} />
          <Route path="/privacy" element={<PrivacyPolicyPage />} />
          <Route path="/terms" element={<TOSPage />} />
          <Route path="/responsible-gambling" element={<GamblingResponsiblyPage />} />
          <Route path="/evbetting" element={<EVBettingPage />} />
          <Route path="/data" element={<OurDataPage />} />
          <Route path="/sitemap" element={<Sitemap />} />
          
          {/* Protected and semi-protected routes */}
          <Route path="/games" element={<PicksPage picks={picks} cur_date={curDate} user={user} />} />
          <Route path="/game/:id" element={<GameDetailsPage games={picks} user={user} />} />
          <Route path="/blog" element={<BlogPage user={user} />} />
          <Route path="/blog/:id" element={<BlogPage user={user} />} />
          
          {/* Authentication routes */}
          <Route path="/account" element={<AccountPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/logout" element={<LogoutPage />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;