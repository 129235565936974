import React from 'react';

const TermsOfService = () => {
  return (
    <div className="max-w-2xl mx-auto p-6 bg-white shadow-md rounded-lg">
      <h1 className="text-3xl font-bold mb-4 text-gray-800">Terms of Service</h1>
      <p className="mb-4 text-gray-600">
        Our Terms of Service are hosted externally. You can view them by clicking the link below:
      </p>
      <a 
        href="https://www.freeprivacypolicy.com/live/2979dcfb-7056-4fa5-9313-cd7ef4ee30b4" 
        target="_blank" 
        rel="noopener noreferrer"
        className="inline-block bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-300"
      >
        View our Terms of Service
      </a>
    </div>
  );
};

export default TermsOfService;