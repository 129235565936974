import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDq-tunSsz3vXZb3LjJf6RMX-EFIu866wM",
  authDomain: "daily-lockz.firebaseapp.com",
  projectId: "daily-lockz",
  storageBucket: "daily-lockz.appspot.com",
  messagingSenderId: "975858994799",
  appId: "1:975858994799:web:4fe8077f2208f5af9ec79a",
  measurementId: "G-R24BNJJMD7"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);