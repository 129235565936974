import React from 'react';
import { useParams, Link } from 'react-router-dom';

const calculateImpliedOdds = (probability) => {
  const decimalOdds = 100 / probability;
  let americanOdds;
  if (decimalOdds >= 2) {
    americanOdds = Math.round((decimalOdds - 1) * 100);
  } else {
    americanOdds = Math.round(-100 / (decimalOdds - 1));
  }
  return americanOdds;
};

const GameDetailsPage = ({ games }) => {
  const { id } = useParams();
  const decodedId = decodeURIComponent(id);
  const game = games.find(g => `${g.home_team}${g.time}` === decodedId);

  if (!game) {
    return <div className="game-not-found">Game not found</div>;
  }

  // Round scores to 3 decimal places
  const homeScore = parseFloat(game.h_score).toFixed(3);
  const awayScore = parseFloat(game.a_score).toFixed(3);

  // Calculate spread and total score using the rounded values
  const spread = (parseFloat(homeScore) - parseFloat(awayScore)).toFixed(3);
  const totalScore = (parseFloat(homeScore) + parseFloat(awayScore)).toFixed(3);

  // Determine winning and losing scores
  const homeWinning = parseFloat(homeScore) > parseFloat(awayScore);
  const winningTeam = homeWinning ? game.home_team : game.away_team;
  const losingTeam = homeWinning ? game.away_team : game.home_team;

  // Calculate win and lose probabilities
  const winProbability = parseFloat(game.win_prob);
  const loseProbability = 100 - winProbability;

  // Calculate implied American odds
  const winOdds = calculateImpliedOdds(winProbability);
  const loseOdds = calculateImpliedOdds(loseProbability);

  return (
    <div className="game-details-container bg-gray-100 py-4 px-4 sm:px-6 lg:px-8">
      <div className="game-details-page bg-white rounded-lg shadow-md max-w-lg mx-auto p-6">
        <div className="flex items-center mb-4">
          <Link to="/games" className="text-blue-500 hover:text-blue-600">&larr; Back to All Games</Link>
        </div>
        <h2 className="game-title text-2xl mb-4">{game.home_team} vs {game.away_team}</h2>
        <div className="game-info mb-4">
          <p><strong>League:</strong> {game.sport}</p>
          <p><strong>Time:</strong> {game.time}</p>
        </div>
        <div className="team-scores flex justify-between mb-4">
          <div className={`team flex-1 ${homeWinning ? 'text-green-600' : 'text-red-600'}`}>
            <h3 className="text-lg font-bold mb-2">{game.home_team}</h3>
            <p className="score">Score: {homeScore}</p>
          </div>
          <div className={`team flex-1 ${!homeWinning ? 'text-green-600' : 'text-red-600'}`}>
            <h3 className="text-lg font-bold mb-2">{game.away_team}</h3>
            <p className="score">Score: {awayScore}</p>
          </div>
        </div>
        <div className="game-stats bg-gray-200 rounded-lg p-4 mb-4">
          <p><strong>Predicted Spread:</strong> {Math.abs(spread)}</p>
          <p><strong>Predicted Total Score:</strong> {totalScore}</p>
          <p><strong>Win Probability:</strong> {winningTeam} ({winProbability.toFixed(2)}%), line should be {winOdds > 0 ? '+' : ''}{winOdds}</p>
          <p><strong>Lose Probability:</strong> {losingTeam} ({loseProbability.toFixed(2)}%), line should be {loseOdds > 0 ? '+' : ''}{loseOdds}</p>
        </div>
      </div>
    </div>
  );
};

export default GameDetailsPage;