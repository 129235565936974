import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-black text-gray-400 p-8 w-full box-border flex flex-col">
      <div className="flex flex-col lg:flex-row justify-between items-start mb-8">
        <div className="flex-1 max-w-md mb-4 lg:mb-0">
          <p className="pb-2">Some Data Provided By:</p>
          <a href="https://the-odds-api.com" aria-label="TheOddsAPI">
            <img src="/oddsapi.png" alt="API" className="w-40 h-auto" />
          </a>
        </div>
        <div className="flex-1 max-w-md lg:ml-4">
          <h4 className="font-bold">FOLLOW US</h4>
          <div className="flex gap-4 items-center mt-2">
            <a href="https://tiktok.com/@daily_lockz" aria-label="TikTok" className="transition-transform transform hover:scale-110">
              <img src="/tiktoklogo.png" alt="TikTok" className="w-8 h-8 object-contain" />
            </a>
            <a href="https://instagram.com/daily_lockz" aria-label="Instagram" className="transition-transform transform hover:scale-110">
              <img src="/instagramlogo.png" alt="Instagram" className="w-8 h-8 object-contain" />
            </a>
          </div>
        </div>
      </div>
      <nav className="flex flex-wrap gap-4 mb-6">
        <a href="/about" className="text-blue-400 hover:underline">About Us</a>
        <a href="/contact" className="text-blue-400 hover:underline">Contact Us</a>
        <a href="/privacy" className="text-blue-400 hover:underline">Privacy Policy</a>
        <a href="/terms" className="text-blue-400 hover:underline">Terms Of Service</a>
        <a href="/sitemap" className="text-blue-400 hover:underline">Sitemap</a>
        <a href="/responsible-gambling" className="text-blue-400 hover:underline">Gambling Responsibly</a>
        <a href="/evbetting" className="text-blue-400 hover:underline">+EV Betting</a>
        <a href="/data" className="text-blue-400 hover:underline">Our Data</a>
      </nav>
      <div className="text-sm mb-4">
        <p>Disclaimer: All of the information on this site is for entertainment purposes only. We do not accept bets of any kind. The information we provide is accurate and trustworthy to help you make better decisions. When you click or tap on a link on Daily Lockz that leads to a third-party website that we have a commercial arrangement with (such as an online sportsbook), we may earn referral fees. Daily Lockz does not endorse or encourage illegal or irresponsible gambling in any form. Before placing any wagers with any betting site, you must check the online gambling regulations in your jurisdiction or state, as they do vary. If you or someone you know has a gambling problem, crisis counseling and referral services can be accessed by calling 1-800-GAMBLER.</p>
      </div>
      <div className="text-sm text-gray-400">
        <p>Copyright Trevor Scholz 2024 | trevorsholz1@icloud.com | trevorscholz.dev | github.com/trevorscholz1/portfolio</p>
      </div>
    </footer>
  );
};

export default Footer;