import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import fm from 'front-matter';
import Papa from 'papaparse';
import seedrandom from 'seedrandom';

const HomePage = () => {
  const handleViewAllPicks = () => {
    window.scrollTo(0, 0);
  };

  const [games, setGames] = useState([]);
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchGames = async () => {
      try {
        const response = await fetch('/all_sims.csv');
        const reader = response.body.getReader();
        const result = await reader.read();
        const decoder = new TextDecoder('utf-8');
        const csv = decoder.decode(result.value);
        const { data } = Papa.parse(csv, { header: true });
        
        const gameList = data
          .filter(game => 
            game.home_team && 
            game.away_team && 
            game.h_score && 
            game.a_score && 
            game.time &&
            game.home_team !== game.away_team
          )
          .map(game => ({
            home_team: game.home_team,
            away_team: game.away_team,
            h_score: game.h_score,
            a_score: game.a_score,
            time: game.time,
          }));

        const seed = 'dailylockz';
        const rng = seedrandom(seed);

        const shuffledGames = gameList
          .map(value => ({ value, sort: rng() }))
          .sort((a, b) => a.sort - b.sort)
          .map(({ value }) => value);

        setGames(shuffledGames.slice(0, 3));
      } catch (error) {
        console.error('Error fetching or parsing CSV:', error);
      }
    };

    const fetchPosts = async () => {
      try {
        const importAll = (r) => r.keys().map(r);
        const markdownFiles = importAll(require.context('../blog-posts', false, /\.md$/));

        const fileContents = await Promise.all(markdownFiles.map(file => fetch(file).then(res => res.text())));
        
        const parsedPosts = fileContents.map(file => {
          const { attributes, body } = fm(file);
          return { ...attributes, content: body };
        });

        const sortedPosts = parsedPosts.sort((a, b) => new Date(b.date) - new Date(a.date));

        setPosts(sortedPosts.slice(0, 2));
      } catch (error) {
        console.error('Error fetching or parsing markdown files:', error);
      }
    };

    fetchGames();
    fetchPosts();
  }, []);

  return (
    <div className="home-page max-w-full mx-auto bg-gray-100 text-gray-800 pb-8 min-h-screen flex flex-col">
      <header className="hero bg-hero-pattern bg-cover bg-top bg-no-repeat text-white py-24 text-center relative">
        <div className="hero-content relative z-10 max-w-5xl mx-auto px-4">
          <h1 className="text-5xl font-bold text-blue-500">Welcome to Daily Lockz</h1>
          <p className="text-xl mt-4">Your go-to source for daily sports predictions and picks.</p>
          <Link to="/games" className="cta-button bg-blue-500 text-white py-2 px-4 rounded mt-6 inline-block">Check Today's Picks</Link>
        </div>
        <div className="absolute inset-0 bg-black opacity-50"></div>
      </header>

      <section className="features content-padding px-8 my-12 flex flex-col lg:flex-row justify-between gap-8 mb-8">
        <div className="feature bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-2xl mb-4 text-blue-600">Accurate Predictions</h2>
          <p>We provide the most accurate and up-to-date sports predictions to help you make informed decisions. You now have all the tools you need to win, right at your fingertips.</p>
        </div>
        <div className="feature bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-2xl mb-4 text-blue-600">Advanced AI Models</h2>
          <p>We use advanced machine learning models to make our predictions, and then simulate our games 99,000,000 times to get the most accurate numbers possible. Our models are trained on the most advanced stats available for each sport.</p>
        </div>
        <div className="feature bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-2xl mb-4 text-blue-600">Daily Updates</h2>
          <p>Stay updated with the latest scores and predictions, refreshed daily for your convenience. We cover all major US sport leagues and almost all international soccer.</p>
        </div>
      </section>

      <section className="recent-picks content-padding px-8 mb-8">
        <h2 className="text-3xl text-gray-800 mb-6">Featured Games</h2>
        {games.length > 0 ? (
          <ul>
            {games.map((game, index) => (
              <li key={index} className="bg-white p-4 rounded-md shadow-md mb-4">
                {game.away_team} vs {game.home_team}: {game.time}
                <Link to={`/game/${encodeURIComponent(game.home_team)}${encodeURIComponent(game.time)}`} className="view-details text-blue-600 text-sm flex flex-col items-end justify-between min-w-[100px] mt-2" onClick={handleViewAllPicks}>View Details</Link>
              </li>
            ))}
          </ul>
        ) : (
          <p>No games available at the moment.</p>
        )}
        <Link to="/games" className="view-all-button inline-block mt-4 bg-blue-600 text-white px-4 py-2 rounded-md font-bold transition duration-300 hover:bg-blue-500" onClick={handleViewAllPicks}>View All Picks</Link>
      </section>

      <section className="testimonials content-padding px-8 mb-8">
        <h2 className="text-3xl text-gray-800 mb-6">What Our Users Say</h2>
        <blockquote className="bg-white p-4 rounded-md shadow-md mb-4 italic text-gray-800">"Daily Lockz has helped me make smarter bets and win more often. Highly recommend!" - Adam S.</blockquote>
        <blockquote className="bg-white p-4 rounded-md shadow-md italic text-gray-800">"The predictions are spot on. This is my go-to site for sports picks." - Sarah K.</blockquote>
      </section>

      <section className="recent-posts content-padding px-8 mb-8">
        <h2 className="text-3xl text-gray-800 mb-6">Recent Posts</h2>
        {posts.length > 0 ? (
          <ul>
            {posts.map((post, index) => (
              <li key={index} className="recent-post bg-gray-50 border-l-4 border-blue-600 p-4 mb-4">
                <Link to={`/blog/${post.id}`} className="blog-link">
                  <h3 className="text-2xl text-gray-800 mb-2">{post.title}</h3>
                  <p className="text-gray-600">{post.excerpt}</p>
                  <p className="text-sm text-gray-500">{new Date(post.date).toLocaleDateString()}</p>
                </Link>
              </li>
            ))}
          </ul>
        ) : (
          <p>No posts available at the moment.</p>
        )}
        <Link to="/blog" className="view-all-button inline-block mt-4 bg-blue-600 text-white px-4 py-2 rounded-md font-bold transition duration-300 hover:bg-blue-500">View All Posts</Link>
      </section>

      <section className="subscribe content-padding px-8 mb-8">
        <h2 className="text-3xl text-gray-800 mb-4">Stay Updated</h2>
        <p className="mb-4">Subscribe to our newsletter to get the latest picks and updates directly to your inbox.</p>
        <form className="flex gap-4">
          <input type="email" placeholder="Enter your email" className="flex-1 p-2 rounded-md border border-gray-300" />
          <button type="submit" className="bg-blue-600 text-white px-4 py-2 rounded-md font-bold transition duration-300 hover:bg-blue-500">Subscribe</button>
        </form>
        <p className="disclaimer text-xs text-gray-500 mt-2">You can unsubscribe at any time. By signing up, you agree to our privacy policy.</p>
      </section>
    </div>
  );
};

export default HomePage;