import React from 'react';
import { Link } from 'react-router-dom';

const Sitemap = () => {
  return (
    <div className="bg-gray-100 p-6">
      <h1 className="text-3xl font-bold mb-4">Sitemap</h1>
      <ul className="space-y-2">
        <li>
          <Link to="/" className="text-blue-600 hover:text-blue-800">
            Home
          </Link>
        </li>
        <li>
          <Link to="/games" className="text-blue-600 hover:text-blue-800">
            Games
          </Link>
        </li>
        <li>
          <Link to="/blog" className="text-blue-600 hover:text-blue-800">
            Blog
          </Link>
        </li>
        <li>
          <Link to="/account" className="text-blue-600 hover:text-blue-800">
            Account
          </Link>
        </li>
        <li>
          <Link to="/about" className="text-blue-600 hover:text-blue-800">
            About Us
          </Link>
        </li>
        <li>
          <Link to="/contact" className="text-blue-600 hover:text-blue-800">
            Contact Us
          </Link>
        </li>
        <li>
          <Link to="/privacy" className="text-blue-600 hover:text-blue-800">
            Privacy Policy
          </Link>
        </li>
        <li>
          <Link to="/responsible-gambling" className="text-blue-600 hover:text-blue-800">
            Responsible Gambling
          </Link>
        </li>
        <li>
          <Link to="/evbetting" className="text-blue-600 hover:text-blue-800">
            +EV Betting
          </Link>
        </li>
        <li>
          <Link to="/data" className="text-blue-600 hover:text-blue-800">
            Our Data
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Sitemap;