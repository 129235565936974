import React from 'react';

const GamblingResponsibly = () => {
  return (
    <div className="bg-gray-100 p-6 rounded-lg shadow-lg">
      <h1 className="text-3xl font-bold mb-4 text-blue-700">Gambling Responsibly</h1>
      <p className="mb-4 text-gray-700">
        We at Daily Lockz are committed to promoting responsible gambling practices. We believe that sports betting should be a fun and entertaining activity, but it's important to be aware of the potential risks involved.
      </p>
      <h2 className="text-2xl font-semibold mb-2 text-blue-600">Here are some tips for gambling responsibly:</h2>
      <ul className="list-disc list-inside mb-4 text-gray-700">
        <li>Set a budget and stick to it. Only bet with money you can afford to lose.</li>
        <li>Never chase your losses. If you're losing, take a break and come back another day.</li>
        <li>Don't bet under the influence of alcohol or drugs.</li>
        <li>Gambling should not be your primary source of income.</li>
        <li>Be aware of the signs of gambling addiction and seek help if you need it.</li>
      </ul>
      <p className="mb-4 text-gray-700">
        If you or someone you know has a gambling problem, please contact the following resources for help:
      </p>
      <ul className="list-disc list-inside mb-4 text-gray-700">
        <li>National Problem Gambling Helpline: <a href="tel:1-800-522-4700">1-800-522-4700</a> <a href="https://www.ncpgambling.org/" className="text-blue-600">https://www.ncpgambling.org/</a></li>
        <li><a href="https://www.ncpgambling.org/help-treatment/help-by-state/connecticut/" className="text-blue-600">Connecticut Council on Problem Gambling</a></li>
        <li><a href="https://www.gamblingtherapy.org/" className="text-blue-600">Gamblers Anonymous International</a></li>
      </ul>
      <p className="text-gray-700">
        Remember, gambling should be a fun and entertaining pastime. By following these tips and seeking help if needed, you can ensure that it stays that way.
      </p>
    </div>
  );
};

export default GamblingResponsibly;