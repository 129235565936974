import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Swal from "sweetalert2";
import { signInWithEmailAndPassword, signOut, onAuthStateChanged } from "firebase/auth";
import { auth } from '../firebase';

function Account() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, setUser] = useState(null);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });
  }, []);

  async function handleLogin(e) {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      localStorage.setItem("access_token", await user.getIdToken());
      navigate("/account");
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.message || "An error occurred during login",
      });
    }
  }

  async function handleLogout() {
    try {
      await signOut(auth);
      localStorage.removeItem("access_token");
      navigate("/account");
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.message || "An error occurred during logout",
      });
    }
  }

  if (user) {
    return (
      <div className="container mx-auto mt-10">
        <h2 className="text-2xl font-semibold mb-4">Account</h2>
        <p className="text-gray-600 mb-4">Welcome, {user.email}!</p>
        <button
          onClick={handleLogout}
          className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          LOG OUT
        </button>
      </div>
    );
  } else {
    return (
      <div className="container mx-auto mt-10">
        <form onSubmit={handleLogin} className="max-w-md mx-auto bg-white p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-semibold mb-4">Login to your account</h2>
          <p className="text-gray-600 mb-4">Welcome back!</p>
          <div className="mb-4">
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email address:</label>
            <input
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              id="email"
              required
            />
          </div>
          <div className="mb-6">
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password:</label>
            <input
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              id="password"
              required
            />
          </div>
          <button
            type="submit"
            className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            LOG IN
          </button>
          <p className="mt-4 text-sm">
            Don't have an account?{" "}
            <Link to={"/register"} className="font-medium text-indigo-600 hover:text-indigo-500">
              Create an account
            </Link>
          </p>
          <p className="mt-4 text-sm text-gray-500">
            Demo User: <br />
            Email: user@example.com <br />
            Password: password12345
          </p>
        </form>
      </div>
    );
  }
}

export default Account;