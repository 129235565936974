import React from 'react';

const EVBetting = () => {
  return (
    <div className="bg-gray-100 p-6 rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-4 text-blue-700">What is +EV Betting?</h2>
      <p className="mb-4 text-gray-700">
        In sports betting, Expected Value (EV) refers to the average profit you can expect to make over a series of bets. A positive Expected Value (+EV) indicates that, in the long run, you should make money by placing these bets. Conversely, a negative Expected Value (-EV) means you're likely to lose money over time.
      </p>
      <h3 className="text-xl font-semibold mb-2 text-blue-600">Understanding Odds and Expected Value</h3>
      <p className="mb-4 text-gray-700">
        Let's break it down. Betting odds represent the implied probability of an event happening, set by the sportsbook. For example, odds of +100 mean a $100 bet would win you $100 profit (total return of $200). 
      </p>
      <p className="mb-4 text-gray-700">
        Expected Value considers both the odds offered and the potential payout. We can calculate it with the following formula:
      </p>
      <div className="bg-white p-4 rounded-lg shadow-inner mb-4 text-center font-mono text-lg text-gray-800">
        EV = (Win Probability x Payout) - Cost of Bet
      </div>
      <p className="mb-4 text-gray-700">
        A positive EV means the win probability multiplied by the payout is greater than the cost of the bet. This indicates an edge over the sportsbook, where placing this bet repeatedly should lead to profit in the long run. 
      </p>
      <h3 className="text-xl font-semibold mb-2 text-blue-600">Why is +EV Betting Profitable?</h3>
      <p className="mb-4 text-gray-700">
        Sportsbooks set odds to guarantee themselves a profit regardless of the outcome. However, these odds aren't always perfectly accurate. By analyzing statistics, trends, and other factors, you can identify situations where the true probability of an event is higher than what the odds imply. 
      </p>
      <p className="mb-4 text-gray-700">
        Exploiting these mispriced bets through +EV betting allows you to gain an edge over the sportsbook. Even small edges can compound significantly over time, leading to long-term profitability. 
      </p>
      <h3 className="text-xl font-semibold mb-2 text-blue-600">Important Considerations</h3>
      <p className="mb-4 text-gray-700">
        While +EV betting offers a path to profitability, it's important to remember:
      </p>
      <ul className="list-disc list-inside mb-4 text-gray-700">
        <li>Finding consistent +EV bets requires significant research and analysis.</li>
        <li>Sportsbooks may limit your bets if you win consistently.</li>
        <li>Bankroll management is crucial to manage risk and sustain profits.</li>
      </ul>
      <p className="text-gray-700">
        +EV betting is a powerful tool, but it's not a guaranteed path to riches. Use it alongside responsible betting practices to maximize your chances of success.
      </p>
    </div>
  );
};

export default EVBetting;